<template>
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8 13.26L3.8219 15.8931C3.7021 15.9715 3.57481 16.0068 3.44003 15.9989C3.30525 15.9911 3.18545 15.948 3.08063 15.8696C2.9758 15.7913 2.89718 15.6894 2.84477 15.564C2.79235 15.4386 2.78112 15.2975 2.81107 15.1408L3.91175 10.1566L0.227838 6.79455C0.108036 6.68483 0.0369033 6.56336 0.0144404 6.43014C-0.00802246 6.29691 -0.00427864 6.1676 0.0256719 6.04221C0.0556224 5.91682 0.123011 5.81103 0.227838 5.72482C0.332665 5.63861 0.459955 5.58767 0.609707 5.572L5.48416 5.1253L7.37104 0.42319C7.43094 0.282127 7.52079 0.176329 7.64059 0.105797C7.7604 0.0352658 7.8802 0 8 0C8.1198 0 8.2396 0.0352658 8.35941 0.105797C8.47921 0.176329 8.56906 0.282127 8.62896 0.42319L10.5158 5.1253L15.3903 5.572C15.54 5.58767 15.6673 5.63861 15.7722 5.72482C15.877 5.81103 15.9444 5.91682 15.9743 6.04221C16.0043 6.1676 16.008 6.29691 15.9856 6.43014C15.9631 6.56336 15.892 6.68483 15.7722 6.79455L12.0882 10.1566L13.1889 15.1408C13.2189 15.2975 13.2076 15.4386 13.1552 15.564C13.1028 15.6894 13.0242 15.7913 12.9194 15.8696C12.8145 15.948 12.6947 15.9911 12.56 15.9989C12.4252 16.0068 12.2979 15.9715 12.1781 15.8931L8 13.26Z"
            fill="#7CAEAD"
        />
    </svg>
</template>
