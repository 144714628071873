export function getCookie(name: string) {
    const cookie: { [key: string]: string } = {};
    document.cookie.split(';').forEach(function (el) {
        const [k, v] = el.split('=');
        cookie[k.trim()] = v;
    });
    return cookie[name];
}

export function getCookieDomain(hostname: string = window.location.hostname) {
    const cookieDomain = 'localhost';

    if (hostname.endsWith(cookieDomain)) {
        return cookieDomain;
    }

    const domainLevels = hostname.split('.').slice(-3);

    return `.${domainLevels.join('.')}`;
}

export function deleteCookie(name: string) {
    const cookieDomain = getCookieDomain();
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${cookieDomain}; Secure; SameSite=Lax`;
}

export * from './file';
export * from './initials';
export * from './type';
