<script lang="ts" setup>
import { computed } from 'vue';
import { VAlert } from 'vuetify/components/VAlert';

type AppAlertProps = {
    variant?: 'success' | 'error';
    text: string;
};
const { variant = 'success', text } = defineProps<AppAlertProps>();

const customClass = computed(() => `app-alert app-alert--${variant}`);
</script>

<template>
    <v-alert :class="customClass" color="neutral-variant-99" align="left">
        {{ text }}
    </v-alert>
</template>

<style lang="scss">
.app-alert {
    height: 3rem;
    padding-top: 0;
    padding-bottom: 0;
}

.app-alert--success {
    border-left: 8px solid rgb(var(--v-theme-success));
}

.app-alert--error {
    border-left: 8px solid rgb(var(--v-theme-error));
}
</style>
