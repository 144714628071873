<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM8 18H4V6H8V18ZM20 18H10V6H20V18Z"
            fill="currentColor"
        />
        <path
            d="M14.8211 10.1351L14.2691 9.5L12 12L14.2691 14.5L14.8211 13.8649L13.5271 12.4392L18 12.4392L18 11.5608L13.5271 11.5608L14.8211 10.1351Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0.3"
        />
    </svg>
</template>
