import { defineStore } from 'pinia';
import { reactive } from 'vue';

export interface Notification {
    id: string;
    message: string;
    type: 'success' | 'error';
    clearable?: boolean;
    timer?: number;
}

export const useNotificationStore = defineStore('notification', () => {
    const notificationList = reactive<Notification[]>([]);

    function _createId(notification: Pick<Notification, 'type'>) {
        // Create a random ID for the notification to avoid using index as key
        const randomNumber = Math.floor(1000 + Math.random() * 9000);
        return `${notification.type}-${Date.now()}-${randomNumber}`;
    }

    /**
     *
     * @returns {string} id of the notification
     */
    function add(notification: Omit<Notification, 'id'>): string {
        const id = _createId(notification);
        notificationList.push({ ...notification, id });

        // Remove the notification if it is not clearable or has a timer set
        if (
            !notification.clearable ||
            (notification.clearable && notification.timer)
        ) {
            setTimeout(() => {
                remove(id);
            }, notification.timer || 5000);
        }

        return id;
    }

    function remove(id: string) {
        const index = notificationList.findIndex(
            (notification) => notification.id === id
        );
        if (index === -1) return;

        notificationList.splice(index, 1);
    }

    function clear() {
        notificationList.splice(0, notificationList.length);
    }

    return {
        add,
        clear,
        remove,
        queue: notificationList,
    };
});
