export function isNotNull<T>(arg: T): arg is Exclude<T, null> {
    return arg !== null;
}

export function isNotUndefined<T>(arg: T): arg is Exclude<T, undefined> {
    return arg !== undefined;
}

export type NonNullableFields<T> = {
    [P in keyof T]: NonNullable<T[P]>;
};

export function assertIsString(value: unknown): asserts value is string {
    if (typeof value !== 'string') {
        throw new Error(`Value ${value} is not a string`);
    }
}
