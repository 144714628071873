<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { VIcon } from 'vuetify/components';
import { getFileNameFromPath } from '@/utils';

const props = withDefaults(
    defineProps<{
        modelValue: File | string | null;
        text?: string;
        disabled?: boolean;
        acceptedMimeTypes?: string[];
        maxFileSize?: number;
    }>(),
    {
        modelValue: null,
        text: '',
        acceptedMimeTypes: () => ['image/jpeg', 'image/png'],
        maxFileSize: Infinity,
    }
);

const emit = defineEmits<{
    (e: 'update:modelValue', value: File | null): void;
}>();

const { t } = useI18n({
    messages: {
        nl: {
            text: 'Sleep het bestand hierheen',
            or: 'of',
            select: 'selecteer',
            unselect_file: 'Deselecteer betand',
            hide_error: 'Foutmelding verbergen',
            error_title: 'Foutmelding',
            error_file_type:
                'Bestandsformaat is niet correct, selecteer een: {0}',
            error_file_size: 'Maximale bestandsgrootte van {0} overschreden',
        },
        en: {
            text: 'Drag & drop the file here',
            or: 'or',
            select: 'select',
            unselect_file: 'Unselect file',
            hide_error: 'Hide error',
            error_title: 'Error',
            error_file_type: 'File format is incorrect, please select a: {0}',
            error_file_size: 'Max file size of {0} exceeded',
        },
    },
});

const error = ref('');
const fileIsHovering = ref(false);
const fileInput = ref<HTMLInputElement | null>(null);
const acceptedFileTypes = computed(() => props.acceptedMimeTypes.join(','));
const acceptedFileExtensions = computed(() => {
    return props.acceptedMimeTypes.map((type) => type.split('/').pop());
});

const acceptedFileExtensionsMessage = computed(() => {
    const extensions = acceptedFileExtensions.value;

    if (extensions.length === 1) {
        return extensions[0];
    }

    return (
        extensions.slice(0, -1).join(', ') +
        ` ${t('or')} ` +
        extensions.slice(-1)
    );
});

const maxFileSizeMessage = computed(() => {
    const kb = props.maxFileSize / 1024;
    const mb = kb / 1024;

    if (kb < 1024) {
        return `${kb.toFixed(0)} KB`;
    }

    return `${mb.toFixed(0)} MB`;
});

const selectedFileName = computed(() =>
    typeof props.modelValue === 'string'
        ? getFileNameFromPath(props.modelValue)
        : props.modelValue?.name
);

function handleDrop(event: DragEvent) {
    const { items, files } = event.dataTransfer ?? {};
    fileIsHovering.value = false;

    // If supported, use DataTransferItemList, else use DataTransfer.files
    if (items) {
        Array.from(items).forEach((item) => {
            if (item.kind === 'file') {
                const file = item.getAsFile();

                if (file) {
                    selectFile(file);
                }
            }
        });
    } else if (files) {
        Array.from(files).forEach((file) => {
            selectFile(file);
        });
    }
}

function handleDragEnter() {
    fileIsHovering.value = true;
}

function handleDragLeave(event: DragEvent) {
    const el = event.currentTarget as HTMLDivElement;
    const targetEl = event.relatedTarget as HTMLElement;

    if (!el.contains(targetEl)) {
        fileIsHovering.value = false;
    }
}

function openFileSelect() {
    fileInput.value?.click();
}

async function handleFileSelect(event: Event) {
    const { files } = event.target as HTMLInputElement;
    const file = files?.item(0);

    if (file) {
        selectFile(file);
    }
}

async function selectFile(file: File) {
    const incorrectFileType = !props.acceptedMimeTypes.includes(file.type);

    if (incorrectFileType) {
        unselectFile();
        error.value = t('error_file_type', [
            acceptedFileExtensionsMessage.value,
        ]);
        return;
    } else if (file.size > props.maxFileSize) {
        unselectFile();
        error.value = t('error_file_size', [maxFileSizeMessage.value]);
        return;
    } else if (error.value) {
        clearError();
    }

    emit('update:modelValue', file);
}

function unselectFile() {
    if (fileInput.value) {
        fileInput.value.value = '';
    }

    emit('update:modelValue', null);
}

function clearError() {
    error.value = '';
}
</script>

<template>
    <div
        class="file-upload"
        :class="{
            'file-upload--has-error': error,
            'file-upload--has-file': selectedFileName,
            'file-upload--file-hovering': fileIsHovering,
            'file-upload--disabled': disabled,
        }"
        @drop.prevent="handleDrop"
        @dragenter.prevent="handleDragEnter"
        @dragleave.self.prevent="handleDragLeave"
        @dragover.prevent
    >
        <div class="file-upload__inner">
            <template v-if="selectedFileName">
                <p class="text-label-small">{{ selectedFileName }}</p>
                <v-btn-icon
                    class="file-upload__clear-file-button"
                    size="x-small"
                    icon="$close"
                    :aria-label="t('unselect_file')"
                    @click="unselectFile"
                />
            </template>
            <template v-else-if="error">
                <v-icon class="mb-3" color="error-60" icon="$error" size="40" />
                <p class="text-title-small">{{ t('error_title') }}</p>
                <span class="text-body-small">{{ error }}</span>
                <v-btn-icon
                    class="file-upload__clear-file-button"
                    size="x-small"
                    icon="$close"
                    :aria-label="t('hide_error')"
                    @click="clearError"
                />
            </template>
            <template v-else>
                <v-icon
                    class="mb-3"
                    color="primary-60"
                    icon="$upload"
                    size="40"
                />
                <p class="text-title-small">{{ text || t('text') }}</p>
                <span class="text-body-small">
                    {{ t('or') }}
                    <a
                        class="text-body-small text-decoration-underline"
                        role="button"
                        tabindex="0"
                        @click="openFileSelect"
                    >
                        {{ t('select') }}
                    </a>
                </span>
            </template>
            <input
                ref="fileInput"
                class="d-none"
                type="file"
                :accept="acceptedFileTypes"
                :disabled
                @change="handleFileSelect"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.file-upload {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(var(--v-theme-primary-99));
    border: 1px dashed rgb(var(--v-theme-neutral-50));
    color: rgb(var(--v-theme-surface-variant));
    border-radius: 4px;
    min-height: 128px;
    height: 100%;
    padding: 20px;
    text-align: center;

    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__clear-file-button {
        position: absolute;
        top: 4px;
        right: 4px;
    }

    &--has-error {
        background-color: rgb(var(--v-theme-error-99));
        border-color: rgb(var(--v-theme-error-40));
        color: rgb(var(--v-theme-error-20));
    }

    &--has-file {
        border-style: solid;
    }

    &--file-hovering {
        border-style: dashed;
        background-color: rgb(var(--v-theme-primary-95));

        &.file-upload--has-error {
            background-color: rgb(var(--v-theme-error-95));
        }
    }

    &--disabled {
        opacity: var(--v-disabled-opacity);
        pointer-events: none;
    }
}
</style>
