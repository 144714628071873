<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M21 12L16.5 16.5L15.4 15.4L18.8 12L15.4 8.6L16.5 7.5L21 12ZM8.70001 15.4L7.60001 16.5L3.10001 12L7.60001 7.5L8.70001 8.6L5.30001 12L8.70001 15.4Z"
            fill="currentColor"
        />
    </svg>
</template>
