<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM8 18H4V6H8V18ZM20 18H10V6H20V18Z"
            fill="currentColor"
        />
        <path
            d="M15.1789 13.8649L15.7309 14.5L18 12L15.7309 9.5L15.1789 10.1351L16.4729 11.5608H12V12.4392H16.4729L15.1789 13.8649Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0.3"
        />
    </svg>
</template>
