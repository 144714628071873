<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20 15.425L18.925 16.5L14.425 12L18.925 7.5L20 8.575L16.575 12L20 15.425ZM9.575 12L5.075 16.5L4 15.425L7.425 12L4 8.575L5.075 7.5L9.575 12Z"
            fill="currentColor"
        />
    </svg>
</template>
