<template>
    <svg
        width="13"
        height="22"
        viewBox="0 0 13 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.50129 21.3957C4.87072 21.3957 3.48026 20.8345 2.32989 19.7123C1.17952 18.59 0.60434 17.2193 0.60434 15.6V4.69455C0.60434 3.53462 1.01277 2.54644 1.82964 1.73003C2.64649 0.91361 3.63365 0.505402 4.79111 0.505402C5.96523 0.505402 6.95861 0.91361 7.77126 1.73003C8.58391 2.54644 8.99024 3.54295 8.99024 4.71955V14.6402C8.99024 15.3395 8.75002 15.9335 8.26959 16.4223C7.78916 16.9111 7.19694 17.1555 6.49294 17.1555C5.78896 17.1555 5.1991 16.8968 4.72336 16.3794C4.24765 15.862 4.00979 15.2406 4.00979 14.5152V4.64455H5.33479V14.5902C5.33479 14.9369 5.44765 15.2326 5.67336 15.4774C5.89906 15.7222 6.17511 15.8446 6.50151 15.8446C6.8279 15.8446 7.10345 15.7263 7.32816 15.4899C7.55288 15.2534 7.66524 14.9702 7.66524 14.6402V4.69455C7.66524 3.89455 7.38607 3.21955 6.82774 2.66955C6.26941 2.11955 5.59024 1.84455 4.79024 1.84455C3.99024 1.84455 3.31107 2.11955 2.75274 2.66955C2.19441 3.21955 1.91524 3.89455 1.91524 4.69455V15.6451C1.91524 16.893 2.36338 17.945 3.25966 18.8009C4.15596 19.6568 5.2355 20.0848 6.49826 20.0848C7.7777 20.0848 8.86198 19.6527 9.75111 18.7884C10.6402 17.9241 11.0848 16.8597 11.0848 15.5951V4.64455H12.4098V15.575C12.4098 17.1943 11.8327 18.5692 10.6785 19.6998C9.52423 20.8304 8.13184 21.3957 6.50129 21.3957Z"
            fill="currentColor"
        />
    </svg>
</template>
