export function convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = (event) => resolve(event.target?.result as string);
        fileReader.onerror = (error) => reject(error);
    });
}

export function getFileNameFromPath(fullPath: string) {
    return fullPath.replace(/^.*[\\/]/, '');
}
