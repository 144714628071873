<template>
    <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.69005 13.25H3.75V11.75H9.06502C9.16288 12.2946 9.37123 12.7946 9.69005 13.25ZM1.8077 15.5H7.35996C7.13146 15.9491 7.01175 16.4491 7.00082 17H1.8077C1.30257 17 0.874999 16.825 0.524999 16.475C0.175 16.125 0 15.6974 0 15.1923V1.8077C0 1.30257 0.175 0.874999 0.524999 0.524999C0.874999 0.175 1.30257 0 1.8077 0H15.1923C15.6974 0 16.125 0.175 16.475 0.524999C16.825 0.874999 17 1.30257 17 1.8077V9.00087C16.8586 8.81744 16.7023 8.64007 16.531 8.46875C16.2087 8.14652 15.8651 7.8773 15.5 7.66111V1.8077C15.5 1.73077 15.468 1.66024 15.4039 1.59613C15.3397 1.53203 15.2692 1.49998 15.1923 1.49998H1.8077C1.73077 1.49998 1.66024 1.53203 1.59613 1.59613C1.53203 1.66024 1.49998 1.73077 1.49998 1.8077V15.1923C1.49998 15.2692 1.53203 15.3397 1.59613 15.4039C1.66024 15.468 1.73077 15.5 1.8077 15.5ZM10.6667 7.75H3.75V9.24995H9.39321C9.58422 8.8635 9.84482 8.50519 10.175 8.175C10.3325 8.0175 10.4964 7.87583 10.6667 7.75ZM13.25 5.24995H3.75V3.75H13.25V5.24995ZM9.69238 16.6421V16.9999H17.0001V16.6421C17.0001 15.9755 16.6719 15.4518 16.0154 15.0711C15.359 14.6903 14.4694 14.4999 13.3464 14.4999C12.2232 14.4999 11.3335 14.6903 10.6771 15.0711C10.0206 15.4518 9.69238 15.9755 9.69238 16.6421ZM12.1069 12.6816C12.4472 13.022 12.8604 13.1921 13.3464 13.1921C13.8322 13.1921 14.2453 13.022 14.5856 12.6816C14.926 12.3413 15.0961 11.9281 15.0961 11.4421C15.0961 10.9563 14.926 10.5432 14.5856 10.2029C14.2453 9.86239 13.8322 9.69214 13.3464 9.69214C12.8604 9.69214 12.4472 9.86239 12.1069 10.2029C11.7666 10.5432 11.5964 10.9563 11.5964 11.4421C11.5964 11.9281 11.7666 12.3413 12.1069 12.6816Z"
            fill="#80B0AF"
        />
    </svg>
</template>
