<script setup lang="ts">
import {
    VCard,
    VDialog,
    VToolbar,
    VToolbarTitle,
    VSpacer,
} from 'vuetify/components';

type AppModalProps = {
    title: string;
    width?: string | number;
    height?: string | number;
    persistent?: boolean;
    overflowY?: string;
};

const {
    width = 1128,
    height = 798,
    persistent = false,
    overflowY = 'inherit',
    title,
} = defineProps<AppModalProps>();

const isOpen = defineModel<boolean>({ default: false });

function close() {
    isOpen.value = false;
}

defineExpose({
    close,
});
</script>

<template>
    <v-dialog v-model="isOpen" :width :height :persistent>
        <template #activator="{ props: activatorProps }">
            <slot name="activator" :props="activatorProps" />
        </template>
        <v-card class="flex-fill" rounded="md" :style="{ overflowY }">
            <v-toolbar>
                <v-toolbar-title>
                    {{ title }}
                </v-toolbar-title>
                <v-spacer />
                <v-btn-icon class="mr-4" icon="$close" @click="close" />
            </v-toolbar>
            <slot />
        </v-card>
    </v-dialog>
</template>
